<template>
  <v-container fluid class="pt-0">
    <v-row>
      <v-col cols="12" md="12">
        <v-card-text class="pa-0">
          <v-tabs class="custom-tabs" color="success">
            <v-tab class="text-left justify-start">
              Новини
            </v-tab>
            <v-tab class="text-left justify-start">
              Оголошення
            </v-tab>
            <v-tab class="text-left justify-start">
              Документи
            </v-tab>
            <v-tab class="text-left justify-start">
              Галерея
            </v-tab>
            <v-tab-item class="pa-0">
              <v-row>
                <NewsView/>
              </v-row>
            </v-tab-item>

            <v-tab-item class="pa-0">
              <v-row>
                <NotificationView/>
              </v-row>
            </v-tab-item>

            <v-tab-item class="pa-0">
              <DocumentsView/>
            </v-tab-item>

            <v-tab-item class="pa-0">
              <v-row>
                <GalleryView/>
              </v-row>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "SiteProfile",
  components: {
    NewsView: () => import("@/components/NewsView"),
    NotificationView: () => import("@/components/NotificationView"),
    GalleryView: () => import("@/components/GalleryView"),
    DocumentsView: () => import("@/components/OrganizationDocumentsNew")
  },
  data() {
    return {
    }
  },
  methods: {
  },
  computed: {
  },
  created() {
  },
}
</script>

<style scoped lang="scss">
.custom-tabs {
  :deep(.v-window.v-item-group.theme--light) {
    background-color: transparent;
  }

  :deep(div[role="tablist"]) {
    background-color: #f5f5f5 !important;
  }
}
</style>